import React        from 'react'
import { }          from '../assets/functions'
import { Grid, 
    Typography }    from '@material-ui/core'
import {
    Instagram,
    Facebook    }   from '@material-ui/icons'

export default class Footer extends React.Component {
    state = {
        bdpCookies : undefined
    }
    componentDidMount(){
        const bdpCookies = localStorage.getItem("bdpCookies")
        
        this.setState({bdpCookies})
    }

    cookiesAccept = () => {
        const bdpCookies ="Accepted"
        localStorage.setItem("bdpCookies", bdpCookies)
        this.setState({bdpCookies})
    }
    
    checkCookies = () => {
        const {bdpCookies} = this.state
        if(!bdpCookies){
            return (
                <Grid spacing={1} container className="flex-center app-footer-cookies">
                    <Grid item xs={12} md={8} lg={6}>
                        Utilizamos <b>cookies</b> para entender o comportamento dos usuários e para <b>propósitos estatísticos</b>.<br/>Ao continuar utilizando este site, <b>concorda</b> com o uso dos cookies.  
                        Saiba mais em <b><a href="/politica/privacidade">Política de Privacidade</a></b>.
                    </Grid>
                    <Grid item xs={12} md={2} lg={1}>
                        <button className="app-footer-cookies-btn" onClick={this.cookiesAccept}>
                            ACEITAR
                        </button>
                    </Grid>
                </Grid>
            )
        }
        return <></>
    }

    render() {
        const central = window.location.pathname.indexOf('/central/')===0;
        
        return (
            <Grid container style={{display: this.props.show ? null : "none"}} className={"app-bg-gainsboro"+ (central ? " d-none" : "")}>
                <Grid container item justify="space-around" className={"app-footer-menu app-pt-2 app-pb-2" }>
                    <Grid container item xs={12} lg={5} spacing={3} className=" app-pb-2">
                    <Grid item>
                            <a href="/">Pesquisar</a>
                        </Grid>
                        <Grid item>
                            <a href="/contato/fale-conosco">Contato</a>
                        </Grid>
                        <Grid item>
                            <a href="/servico/integracao">Integrador</a>
                        </Grid>
                        <Grid item> 
                            <a href="/revendas">Anunciantes</a>
                        </Grid>
                        <Grid item>
                            <a href="/politica/privacidade">Políticas</a>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} lg={3}  spacing={3} justify="flex-end">
                        <Grid item>
                            <a rel="noopener noreferrer" href="https://www.facebook.com/bomdapeste" target="_blank"><Facebook /></a>
                        </Grid>
                        <Grid item>
                            <a rel="noopener noreferrer" href="https://www.instagram.com/bomdapeste" target="_blank"><Instagram /></a>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item justify="center" className="app-footer-copyright app-pt-1">
                    <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom>© Bomdapeste 2015-{new Date().getFullYear()} Todos os direitos reservados</Typography>
                    </Grid>
                </Grid>
                {this.checkCookies()}
            </Grid>
        )
    }
}               
