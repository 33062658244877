import React from 'react'
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import PropTypes from 'prop-types';

export default function ElevationScroll(props) {
    const { children } = props;
    
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window : undefined,
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
      position: trigger ? "fixed" : "relative"
    });
  }
  
ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired
};