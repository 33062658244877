import React, { Suspense }  from 'react'
import Axios                from 'axios'
import Head                 from './externo/Head'
import Footer               from './externo/Footer'
import {myServer}           from './assets/constants'
import {
    getIp,
    setCookie,
    setMat,
    getCookie,
    getMat  }               from './assets/functions'
import {
  Switch, Route,
  BrowserRouter as Router } from "react-router-dom"

import './app.scss'

//import Home                 from './externo/Home'
const Home      = React.lazy(() => import('./externo/Home')),
    AdDetails   = React.lazy(() => import('./externo/AdDetails')),
    ShopDetails = React.lazy(() => import('./externo/ShopDetails')),
    Politics    = React.lazy(() => import('./externo/Politics')),
    Services    = React.lazy(() => import('./externo/Services')),
    Shops       = React.lazy(() => import('./externo/Shops')),
    Message     = React.lazy(() => import('./externo/Message')),
    Login       = React.lazy(() => import('./central/Login')),
    AdsList     = React.lazy(() => import('./central/AdsList')),
    AdInsert     = React.lazy(() => import('./central/AdInsert'));


Axios.defaults.withCredentials = true

export default class App extends React.Component{
    state = {
        user        : [],
        shops       : [],
        marks       : [],
        redirect    : "",
        showFooter  : window.location.path!=='/' ? true : false,
        location    : {  } //city:"", ip:"", lat:0, lng: 0
    }

    getIpLocation = async (ip) => {
        const   {data}                  = await Axios.post(`${myServer}/api/geolocal/${ip}`),
         {  geoplugin_city,
            geoplugin_regionCode,
            geoplugin_latitude,
            geoplugin_longitude }       = data;
            
        if(geoplugin_latitude && geoplugin_longitude){ 
            const city = geoplugin_city+'/'+geoplugin_regionCode,
                   lat = geoplugin_latitude * 1,
                   lng = geoplugin_longitude * 1;

            setCookie("pos",setMat([lat, lng, city, ip]),30)
            this.setLocation({ip, city, lat, lng})

            //console.log("Loction", {ip, city, lat, lng })
        }
    }

    setLocation = (location) => this.setState({location})

    changeLocation = obj => {
        const location = {...this.state.location, ...obj},
            {lat, lng, city, ip} = location;
        //console.log(location)
        setCookie("pos",setMat([lat, lng, city, ip]),30)
        this.setLocation({ip, city, lat, lng})
    }
    
    handleShowFooter = () => {
        //this.setState({showFooter:true})
    }

    componentDidMount(){
        try{
            const pos = getCookie("pos")
            if(pos){
                const data = getMat(JSON.parse(`[${pos}]`)),
                   lat = data[0],
                   lng = data[1],
                  city = data[2],
                   ip  = data[3];
                   
                 this.setLocation({ip, city, lat, lng})
            }else getIp((ip) => this.getIpLocation(ip) )
        }catch(e){ console.log(e) }
    }

    setUser = user => this.setState({user})

    setUserShops = (user, shops) => this.setState({user, shops})

    getLocation = () => this.state.location

    WaitingComponent(Component, data) {
        return props => (
        <Suspense fallback={<></>}>
            <Component {...props} data={data}/>
        </Suspense>
        );
    }
    
    render(){
        const {marks, showFooter, location, user, shops, redirect} = this.state
        
        if(redirect.length)
            return <React to={redirect} /> 

        return(
            <Router>
                <Head funcSetUserShops={this.setUserShops} funcSetLocation={this.setLocation}/>
                <Switch>
                    <Route exact path="/comprar/:uf/:cidade/:marca/:modelo/detalhes/:id" component={this.WaitingComponent(AdDetails,{user, location})} />
                    <Route exact path="/revenda/:nome/:cidade/:uf/detalhes/:id" component={this.WaitingComponent(ShopDetails,{user, location})} />
                    <Route path="/comprar/:tipo/:item" component={this.WaitingComponent(Home, {location})}/>
                    <Route path="/politica/:tipo" component={this.WaitingComponent(Politics, {location})}/>
                    <Route path="/servico/:tipo" component={this.WaitingComponent(Services, {user,location})}/>
                    <Route path="/revendas" component={this.WaitingComponent(Shops, {location})}/>
                    <Route path="/contato/:tipo" component={this.WaitingComponent(Message, {user, location})}/>
                    <Route path="/central/entrar" component={this.WaitingComponent(Login, {user, shops, funcSetUserShops:this.setUserShops})}/>
                    <Route path="/central/anuncios/:id" component={this.WaitingComponent(AdsList, {user, shops, funcSetUserShops:this.setUserShops})}/>
                    <Route path="/central/anuncio/inserir/:id" component={this.WaitingComponent(AdInsert, {user, shops, funcSetUserShops:this.setUserShops})}/>
                    <Route path="/" component={this.WaitingComponent(Home, {user, location, funcChangeLocation: this.changeLocation})}/>
                </Switch>
                <Footer marks={marks} show={showFooter}/>
            </Router>
        )
    }
}