import React        from 'react'
import Axios    from 'axios'
import {
    AppBar,
    Toolbar,
    IconButton,  
    Avatar,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Drawer}         from '@material-ui/core'

import {
    ReceiptOutlined,
    MailOutlined,
    ShareOutlined,
    StoreOutlined,
    ExitToApp,
    LoyaltyOutlined,
    MoreHoriz }     from '@material-ui/icons'

import
    ElevationScroll from './ElevationScroll'

import logo         from '../assets/imgs/logo_white.png'
import { myServer } from '../assets/constants'
import { getMat, isMobile }   from '../assets/functions'

export default class Head extends React.Component {
    state = {
        user: null,
        showMenu : false,
        menuOptions: [
            {icon:<MailOutlined />,     desc:"Contato",         link:"/contato/fale-conosco", ext: true},
            {icon:<MailOutlined />,     desc:"Contato",         link:"#", ext: false},
            {icon:<ShareOutlined />,    desc:"Integrador",      link:"/servico/integrador", ext: true},
            {icon:<ShareOutlined />,    desc:"Integrador",      link:"#", ext: false},
            {icon:<StoreOutlined />,    desc:"Anunciantes",     link:"/revendas", ext: true},
            {icon:<StoreOutlined />,    desc:"Dados da Revenda",link:"/#", ext: false},
            {icon:<ReceiptOutlined />,  desc:"Políticas",       link:"/politica/privacidade", ext: true},
            {icon:<ReceiptOutlined />,  desc:"Políticas",       link:"/politica/privacidade", ext: false},
            {icon:<LoyaltyOutlined />,  desc:"Meus Anúncios",   link: window.location.href.indexOf("central")>0 ? '/central/entrar' : (isMobile() ? `/mobile/entrar` : `/anuncio/listar`), ext: false},
            {icon:<ExitToApp />,        desc:"Sair",            link:`${myServer}/sair`, ext:false},
        ]
    }

    toggleDrawer = open => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))
            return
        
        this.setState({ showMenu: open });
    }

    getUserSession = async () => {
        const {data} = await Axios.get(`${myServer}/api/user/session`)
        if(data.u){
            const user = getMat(data.u),
                  shops= data.s;

            this.setState({user})
            this.props.funcSetUserShops(user, shops)
        }
    }

    componentDidMount(){
        this.getUserSession()
    }
    

    sideList = () => {
        const {menuOptions, user} = this.state
        let Options = []

        menuOptions.forEach(m => Options.push(m))

        // if(user && user._id){
        //     Options.push({icon:<LoyaltyOutlined />,    desc:"Meus Anúncios", link: window.location.href.indexOf("central")>0 ? '/central/entrar' : (isMobile() ? `/mobile/entrar` : `/anuncio/listar`)})
        //     Options.push({icon:<ExitToApp />,    desc:"Sair", link:`${myServer}/sair`})
        // }
        return(
            <div
            style={{width:"220px"}}
            role="presentation"
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
            >
                <List>
                    {Options
                     .filter(e => user && user._id ? e.ext===false : e.ext===true)
                     .map((menu, index) => (
                        <a href={menu.link} key={index}>
                            <ListItem button>
                                <ListItemIcon>{menu.icon}</ListItemIcon>
                                <ListItemText primary={menu.desc} />
                            </ListItem>
                        </a>
                    ))}
                </List>
            </div>
        )
    }

    handleUserClick = () =>{
        const {user} = this.state
        
        if(user && user.nome) window.location.href = myServer + (isMobile() ? '/mobile/entrar' : '/anuncio/listar')
        else                  window.location.href = myServer + (isMobile() ? '/mobile/entrar' : '/entrar/?op=l') 
    }
    render() {
        const {showMenu, user} = this.state,
            central = window.location.pathname.indexOf('/central/')===0,
            hideMenu = isMobile() && window.location.search.indexOf("menu=off")>0;
        //console.log(hideMenu)
        return (
            <div > 
                <ElevationScroll {...this.props}>
                    <AppBar position="static" className="app-nav-top"> 
                        <Toolbar>
                            <div className="app-nav-logo">
                                <a href="/"><img src={logo} alt="bomdapeste logomarca" style={{float: hideMenu ? "right" : "none"}}/></a>
                            </div>
                            <IconButton className={"app-nav-login"+(central ? " d-none" : "")} onClick={this.handleUserClick} style={{display: hideMenu ? "none" : null}}>
                                <Avatar alt="Login" src="/imgs/cacto-mini-verde.png" className="app-nav-login-img" />
                                <span className="app-user-nome app-ellipsis">{user && user.nome ? user.nome : "Entrar"}</span>
                            </IconButton>
                            <IconButton edge="start" className="app-nav-menu" color="inherit" aria-label="menu" onClick={this.toggleDrawer(true)} style={{display: hideMenu ? "none" : null}}>
                                <MoreHoriz />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </ElevationScroll>
                <Drawer anchor="right" open={showMenu} onClose={this.toggleDrawer(false)}>
                    {this.sideList()}
                </Drawer>
            </div>
        )
    }
}


