export const getHeaderAuthToken = () => ({'x-access-token': decodeToken(localStorage.getItem("authtoken"), true)}) 

export const userLogout = () => {
	localStorage.removeItem("user")
	localStorage.removeItem("shop")
	localStorage.removeItem("shops")
	localStorage.removeItem("token")
    document.location.href="/"
}

export function decodeToken(input, decoding=false){ 
	var arrayData,
		dataBits    = 8,
		codeBits    = 5,
		pad         = '',
		mag         = 8,
		bitsOut     = decoding ? dataBits : codeBits,
		bitsIn      = decoding ?  codeBits : dataBits, 
		prev        = 0,
		mask        = [],
		keyString   = 'yM5iwsWb9J2qrtpoKlnekE7zjaPD34FO';
	
	//keyString = keyString.substring(24,8)+keyString.substring(8,16)+keyString.substring(0,8)
	
	var gcd = function (a, b) {// greatest common divisor
		var t;
		while (b !== 0) {
			t = b;
			b = a % b;
			a = t;
		}
		return a;
	};
	
	for (var i = 0; i < mag; i += 1) {
		mask.push(prev);
		prev += prev + 1;
	}
	
	var max = prev,
		group = dataBits / gcd(dataBits, codeBits);
	
	var i, len, chr, byteIn,
		buffer, size, output,
		write;
	
	
	// append a byte to the output
	write = function (n) {
		if (!decoding) {
			output.push(keyString.charAt(n));
		} else if (arrayData) {
			output.push(n);
		} else {
			output.push(String.fromCharCode(n));
		}
	};

	buffer = 0;
	size = 0;
	output = [];
	
	len = input.length;
	for (i = 0; i < len; i += 1) {
		// the new size the buffer will be after adding these bts
		size += bitsIn;
		// read a character
		if (decoding) {
			// decode it
			chr = input.charAt(i);
			byteIn = keyString.indexOf(chr);
			if (chr === pad) {
				break;
			} else if (byteIn < 0) {
				throw 'the character "' + chr + '" is not a member of ' + keyString;
			}
		} else {
			if (arrayData) {
				byteIn = input[i];
			} else {
				byteIn = input.charCodeAt(i);
			}
			if ((byteIn | max) !== max) {
				throw byteIn + " is outside the range 0-" + max;
			}
		}
		
		// shift the buffer to the left and add the new bits
		buffer = (buffer << bitsIn) | byteIn;
		// as long as there's enough in the buffer for another output...
		while (size >= bitsOut) {
			// the new size the buffer will be after an output
			size -= bitsOut;
			
			// output the part that lies to the left of that number of bits
			// by shifting the them to the right
			var b = buffer >> size
			write(b);
			
			// remove the bits we wrote from the buffer
			// by applying a mask with the new size
			buffer &= mask[size];
		}
	}

	// If we're encoding and there's input left over, pad the output.
	// Otherwise, leave the extra bits off, 'cause they themselves are padding
	if (!decoding && size > 0) {
	
		// flush the buffer
		write(buffer << (bitsOut - size));

		// add padding string for the remainder of the group
		while (output.length % group > 0) {
			output.push(pad);
		}
	}
	// string!
	return (arrayData && decoding) ? output : output.join('')
}